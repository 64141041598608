<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Create new lesson
			</div>
		</div>
		<div>
			<lesson-edit
				:isCreate="true"
			/>
		</div>
	</div>
</template>

<script>
	import LessonEdit  from '@/components/lessons/LessonEdit';

	export default {
		metaInfo: {
			title: 'Create new lesson'
		},
		components: {
			LessonEdit
		},
		layout: 'v2default',

		computed: {
			getCourseId () {
				return parseInt(this.$route.params.courseId);
			},
			getBreadcrumbRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/teaching/courses/${this.getCourseId}/lessons`;
			}
		}
	};

</script>
